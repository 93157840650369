/* Navbar Container */
.navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 20px;
    background-color: #2874f0;
    color: white;
    position: relative;
  }
  
  /* Logo Styling */
  .navbar-logo a {
    color: white;
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
    margin-right: 20px;
  }
  
  /* Search Bar Styling */
  .navbar-search {
    display: flex;
    flex: 1;
    align-items: center;
    margin-right: 20px;
  }
  
  .navbar-search input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px 0 0 4px;
    font-size: 16px;
  }
  
  .navbar-search button {
    padding: 10px;
    border: none;
    background-color: #ffa600;
    border-radius: 0 4px 4px 0;
    color: white;
    cursor: pointer;
  }
  
  /* Toggle Button Styles */
  .navbar-toggle-checkbox {
    display: none; /* Hide the checkbox */
  }
  
  .navbar-toggle-label {
    display: none; /* Hide by default */
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 24px;
    color: white;
  }
  
  .navbar-toggle-label span {
    display: block;
    width: 30px;
    height: 3px;
    background: white;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  /* Menu Styling */
  .navbar-menu {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
  }
  
  .navbar-menu a {
    color: white;
    text-decoration: none;
    margin: 0 15px;
    font-size: 16px;
  }
  
  /* Categories Dropdown */
  .navbar-categories {
    position: relative;
  }
  
  .navbar-categories span {
    cursor: pointer;
    margin: 0 15px;
    position: relative;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #d7b69f;
    color: #333;
    width: 300px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: none; /* Hide dropdown by default */
    flex-wrap: wrap;
    padding: 20px;
    z-index: 1000;
    transition: opacity 0.3s ease;
  }
  
  /* Show Dropdown on Hover */
  .navbar-categories:hover .dropdown-menu {
    display: flex;
  }
  
  /* Dropdown Item Styling */
  .dropdown-item {
    flex: 1 1 50%;
    margin: 10px 0;
  }
  
  .dropdown-item h4 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #2874f0;
  }
  
  .dropdown-item ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-item ul li {
    margin: 5px 0;
  }
  
  .dropdown-item ul li a {
    text-decoration: none;
    color: #333;
  }
  
  /* Actions Styling */
  .navbar-actions {
    display: flex;
    align-items: center;
  }
  
  .navbar-actions a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    display: flex;
    align-items: center;
  }
  
  .navbar-actions span {
    margin-left: 5px;
  }
  
  .cart-badge {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
  }
  /* Toggle Button Styles */
.navbar-toggle-button {
    display: none; /* Hidden by default */
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 20px;
    z-index: 1100; /* Higher z-index to be on top */
  }
  
  @media (max-width: 768px) {
    /* Show toggle button */
    .navbar-toggle-button {
      display: block;
    }
  
    /* Menu and actions are hidden by default */
    .navbar-menu,
    .navbar-actions {
      display: none;
      width: 100%;
      flex-direction: column;
      align-items: center;
      background-color: #2874f0;
      padding: 20px 0;
    }
  
    /* Show menu and actions when open class is applied */
    .navbar-menu.open,
    .navbar-actions.open {
      display: flex;
    }
  
    .navbar-menu a {
      margin: 10px 0;
      font-size: 18px;
    }
  
    .navbar-actions a {
      margin: 10px 0;
    }
  }
  