/* General Styles */
.product-list {
    padding: 20px;
    background-color: #f5f5f5;
}

.product-list h1 {
    font-size: 2.5em;
    margin-bottom: 30px;
    text-align: center;
    font-weight: bold;
    color: #333;
}

.product-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

/* Product Card Styles */
.product-card {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 200px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.product-image {
    position: relative;
}

.product-image img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.product-add-to-cart {
    color: #fff;
    background: #000;
    font-size: 16px;
    text-align: center;
    line-height: 50px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    opacity: 0;
    transform: scale(0);
    position: absolute;
    bottom: 10px;
    right: 10px;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
}

.product-card:hover .product-add-to-cart {
    opacity: 1;
    transform: scale(1);
}

.product-add-to-cart:hover {
    background: #ff6f00;
}

.product-info {
    padding: 15px;
}

.product-title {
    font-size: 1.1em;
    margin: 10px 0;
    color: #333;
    font-weight: 500;
    line-height: 1.3;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rating {
    color: #f7bc3d;
    font-size: 14px;
    margin: 8px 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
}

.rating .fa-star {
    margin: 0 2px;
}

.rating .disable {
    color: #a3a3a3;
}

.product-price {
    color: #a3a3a3;
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 10px;
}

/* Section Styles */
.offer-section {
    margin-top: 40px;
    text-align: center;
    background: #fdf4f4;
    border-radius: 12px;
    padding: 20px;
}

.offer-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #b7410e;
}

.offer-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.offer-card {
    background: #ffd8d8;
    border-radius: 12px;
    padding: 20px;
    width: 300px;
    text-align: center;
    font-size: 1.1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.offer-card:hover {
    background-color: #ffb3b3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Additional Sections */
.additional-sections {
    margin-top: 40px;
}

.section {
    margin-bottom: 40px;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.view-all-products {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: background 0.3s ease;
    display: block;
    margin: 20px auto 0;
}

.view-all-products:hover {
    background: #0056b3;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .product-list h1 {
        font-size: 1.8em;
        margin-bottom: 20px;
    }

    .product-card {
        width: 160px;
    }

    .offer-card {
        width: 100%;
        max-width: 300px;
        font-size: 1em;
    }

    .section {
        padding: 10px;
    }
}
