/* ShopByCategory.css */

.category-section {
    padding: 20px;
    background-color: #fff;
    text-align: center;
  }
  
  .category-section h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }
  
  .category-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
  }
  
  .category-card {
    background: #f4f4f4;
    border-radius: 8px;
    padding: 10px;
    width: 120px;
    text-align: center;
    transition: background-color 0.2s ease;
  }
  
  .category-card img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .category-name {
    margin-top: 10px;
    font-size: 0.9em;
  }
  
  @media (max-width: 768px) {
    .category-section h2 {
      font-size: 1.2em;
    }
  
    .category-card {
      width: 100px;
    }
  
    .category-card img {
      width: 80px;
      height: 80px;
    }
  }
  