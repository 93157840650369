/* Footer Styles */
.footer {
    background-color: #333;
    color: #fff;
    padding: 40px 20px;
    text-align: center;
}

.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-section {
    flex: 1;
    min-width: 220px;
    margin: 10px;
    padding: 10px;
}

.footer-heading {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #f4a261;
}

.footer-text {
    font-size: 0.9em;
    line-height: 1.6;
}

.footer-links {
    list-style: none;
    padding: 0;
}

.footer-links li {
    margin: 10px 0;
}

.footer-links a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #f4a261;
}

.footer-socials {
    margin-top: 10px;
}

.social-icon {
    color: #fff;
    font-size: 1.5em;
    margin: 0 10px;
    transition: color 0.3s ease;
}

.social-icon:hover {
    color: #f4a261;
}

.footer-bottom {
    margin-top: 20px;
    font-size: 0.8em;
    color: #aaa;
}

.footer-bottom p {
    margin: 0;
}
