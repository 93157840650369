/* src/components/HeroSection.css */

.hero-section {
    position: relative;
    overflow: hidden;
  }
  
  .hero-content {
    background: rgba(104, 98, 227, 0.253);
    color: white;
    padding: 50px 20px;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  
  .hero-content h1 {
    font-size: 3em;
    margin: 0;
  }
  
  .hero-content p {
    font-size: 1.5em;
    margin: 20px 0;
  }
  
  .hero-content button {
    background-color: #ff7700;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.2em;
    transition: background-color 0.2s ease;
  }
  
  .hero-content button:hover {
    background-color: #000000;
  }
  
  .hero-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    border: none;
    z-index: 0;
  }
  
  .hero-banner {
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .banner-slider {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    gap: 20px;
    width: 100%;
  }
  
  .banner-slide {
    flex: 0 0 auto;
    width: 100%;
    scroll-snap-align: start;
  }
  
  .banner-slide img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .scroll-arrow {
    position: absolute;
    top: 50%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
  }
  
  .scroll-arrow.left {
    left: 10px;
  }
  
  .scroll-arrow.right {
    right: 10px;
  }
  
  .scroll-arrow i {
    font-size: 1.5em;
  }
  
  /* Hide scrollbar for WebKit browsers */
  .banner-slider::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for other browsers */
  .banner-slider {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .hero-content {
      padding: 30px 15px;
    }
  
    .hero-content h1 {
      font-size: 2.5em;
    }
  
    .hero-content p {
      font-size: 1.2em;
    }
  
    .hero-content button {
      font-size: 1em;
      padding: 8px 16px;
    }
  
    .hero-background iframe {
      height: 400px;
    }
  }
  
  @media (max-width: 480px) {
    .hero-content {
      padding: 20px 10px;
    }
  
    .hero-content h1 {
      font-size: 2em;
    }
  
    .hero-content p {
      font-size: 1em;
    }
  
    .hero-content button {
      font-size: 0.9em;
      padding: 6px 12px;
    }
  
    .hero-background iframe {
      height: 300px;
    }
  }
  